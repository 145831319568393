import React, { useEffect, useMemo, useRef, useState } from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Stack, useMediaQuery, useTheme } from "@mui/material";

import { Backdrop, CircularProgress } from "@mui/material";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import Step4 from "./Step4";
import Step5 from "./Step5";
import "../../styles/checkout.css";
import { useSelector, useDispatch } from "react-redux";
import { insertSessionIdSlice, paymentProcess, restoreStateCheckoutNewBd, updateStep1Complete, updateStep2Complete, updateStep3Complete } from "../../../store/checkout/checkoutSlice";
import { secretKey } from "../../../api";
import CryptoJS from "crypto-js";

import { FetchPriceList, addSeatingFunction, geTemporaryPayment, geTemporaryPaymentInsert, geTemporaryPaymentUpdate, getAritucule, insertSessionId } from "../../../store/checkout";

import { useNavigate } from "react-router-dom";
import { restaurarDatosProcesodePago } from "../../../store/auth/authSlice";
import { startLogout } from "../../../store/auth/thunks";
import useInsertTemporarySession from "../../../hooks/updatePymentClient";
import { getTandasBd, restoreStateHomeNewBd, setActiveMovieInfo, updatearrayInfoMovie } from "../../../store/home";
import CountdownTimer from "./CountdownTimer";

/* fecha y hora */
function getCurrentDateTimeInCostaRica() {
    // Obtener la fecha y hora actual en UTC
    const now = new Date();

    // Crear una nueva fecha ajustando la zona horaria a Costa Rica (UTC -6)
    const offset = -6; // Costa Rica está en UTC -6
    const costaRicaTime = new Date(now.getTime() + offset * 60 * 60 * 1000);

    // Formatear la fecha y la hora por separado
    const fecha = costaRicaTime.toISOString().split("T")[0]; // YYYY-MM-DD
    const hora = costaRicaTime.toISOString().split("T")[1].split(".")[0]; // HH:MM:SS

    return { fecha, hora };
}

const generateRandomNumbers = () => Math.floor(100000 + Math.random() * 900000);
const generateRandomLetters = () => {
    const letters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
    let result = "";
    for (let i = 0; i < 3; i++) {
        result += letters.charAt(Math.floor(Math.random() * letters.length));
    }
    return result;
};
const steps = ["USUARIOS", "ENTRADAS", "ASIENTOS", "CONFITERÍA", "CONFIRMACIÓN Y PAGO"];

const Checkout = () => {
    /* funcion que dispacha las acciones del store */
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const generateSessionId = async () => {
        const currentDate = new Date();
        const year = currentDate.getFullYear();
        const month = currentDate.getMonth() + 1;
        const day = currentDate.getDate();
        const hours = currentDate.getHours();
        const minutes = currentDate.getMinutes();
        const seconds = currentDate.getSeconds();
        const randomNumbers1 = generateRandomNumbers();
        const randomNumbers2 = generateRandomNumbers();
        const randomLetters = generateRandomLetters();

        const newIdSession = `cineverse.${randomNumbers1}.${day}${randomNumbers2}${month}.${year}.${hours}.${minutes}.${seconds}.${randomLetters}`;
        // setIdSession(newIdSession);
        await dispatch(insertSessionIdSlice(newIdSession));
        await dispatch(insertSessionId(newIdSession));

        return newIdSession;
    };

    /* extremos la informacion de los estados de redux */
    const { step1, step2, step3, payment } = useSelector((state) => state.checkout);
    const checkoutState = useSelector((state) => state.checkout);
    const homeState = useSelector((state) => state.home);
    const authUser = useSelector((state) => state.auth);

    // Estado para controlar cuándo se completa el efecto asíncrono
    const [isEffectComplete, setIsEffectComplete] = useState(false);
    const [loading, setLoading] = useState(true);

    // Valor donde se guarda el parámetro de la URL correspondiente a la película actual
    const [movieParam, setMovieParam] = useState(null);
    const [iformacionBan, setBanck] = useState("");
    const [TimeCounter, setTimeCounter] = useState();
    const [codeBanck, setcodeBanck] = useState(0);

    // Función asíncrona que contiene toda la lógica de useEffect
    const executeEffect = async (navigate, dispatch, setMovieParam) => {
        // Obtenemos los parámetros de la URL actual utilizando URLSearchParams
        const urlParams = new URLSearchParams(window.location.search);

        // Extraemos el valor asociado al parámetro 'movie' de la URL
        const movieValue = urlParams.get("movie");
        setMovieParam(movieValue);

        // Verificamos si el valor del parámetro 'movie' es nulo, vacío o indefinido
        if (movieValue === null || movieValue === "" || movieValue === undefined) {
            // Si no se ha seleccionado una película, mostramos una alerta al usuario
            alert("Lo sentimos pero no se ha podido verificar la pelicula , por favor seleccione una pelicula para continuar.");

            // Redirigimos al usuario a la página de inicio
            navigate("/");

            // Terminamos la ejecución para evitar lógica adicional
            return;
        }

        // validamos si la pelicula ya existe con la url actual si esta existe entonces cargamos los datos desde el api
        const fetchData = async () => {
            // Dispatch an action to get temporary payment data
            const { data } = await dispatch(geTemporaryPayment(movieValue));

            if (data.statusCode === 210) {
                localStorage.removeItem("step");
                // Check if there's an active movie
                if (!homeState.activeMovieInfo || homeState.activeMovieInfo <= 0) {
                    navigate("/");
                    return;
                }
                const tokenU = await generateSessionId();

                const recoverDta = await dispatch(updatearrayInfoMovie());

                // Get current date and time in Costa Rica
                const { fecha, hora } = getCurrentDateTimeInCostaRica();

                setTimeCounter(hora);

                const precioLista = await dispatch(FetchPriceList());

                const setepPyme = 1;

                const insertartSessionTemporal = await dispatch(
                    geTemporaryPaymentInsert(
                        {
                            peliculaActiva: homeState.activeMovieInfo,
                            informacionPelicula: homeState.allMoviesBillboard,
                            extraInformacion: recoverDta,
                        },
                        {
                            peliculaActiva: checkoutState.activeTanda,
                            precioDelista: checkoutState.step2,
                            precioDelistaArray: precioLista,
                            asientosSeleccionados: checkoutState.step3,
                            articuloSeleccionado: checkoutState.articules || [],
                            timeToken: checkoutState.timeToken,
                            tokenSessions: checkoutState.tokenSessions,
                            payment: checkoutState.payment,
                        },
                        { datosUsuario: authUser },
                        {
                            iformacionBanco: "Sin información chekout",
                        },
                        setepPyme,
                        movieValue,
                        fecha,
                        hora,
                    ),
                );
                setLoading(false);

                // Check if the temporary session insertion was successful
                if (insertartSessionTemporal.data.statusCode !== 200) {
                    // console.log("Error inserting temporary session:", insertartSessionTemporal);
                    alert("Lo sentimos pero no se ha podido verificar la pelicula , por favor seleccione una pelicula para continuar.");
                    navigate("/");
                }
            } else {
                const dataUser = data.data[0];
                await dispatch(restaurarDatosProcesodePago(dataUser.temporary_payment_data_authUser.datosUsuario));
                await dispatch(restoreStateHomeNewBd(dataUser.temporary_payment_data_homeState));
                await dispatch(restoreStateCheckoutNewBd(dataUser.temporary_payment_data_checkoutState));

                setBanck(dataUser.temporary_payment_data_banck);

                setcodeBanck(dataUser.temporary_payment_data_banck.responseCode);

                setTimeCounter(dataUser.temporary_payment_data_time);
                setLoading(false);
                if (dataUser.temporary_payment_data_authUser.datosUsuario.name_user === null) {
                    dispatch(startLogout());
                }

            }
        };

        // Ejecutamos fetchData y esperamos a que termine
        await fetchData();

        // Guardamos el valor del parámetro 'movie' en el estado del componente
        setMovieParam(movieValue);

        // Indicamos que el efecto ha terminado
        setIsEffectComplete(true);
    };

    // Dentro de useEffect llamamos a la función asíncrona
    useEffect(() => {
        // Llamamos a la función asíncrona y esperamos a que se complete
        executeEffect(navigate, dispatch, setMovieParam);
    }, [navigate]); // Dependencia del useEffect para asegurarnos de que 'navigate' esté actualizado

    // Solo ejecutamos el console.log cuando el efecto asíncrono ha terminado
    useEffect(() => {
        if (isEffectComplete) {
            //console.log("Efecto asíncrono completado");
        }
    }, [isEffectComplete]);

    /* nueva integracion */

    /* funciones que deven estar predeterminadas */
    const isCompleteStep2 = useMemo(() => step2.complete === true, [step2.complete]);
    const [activeStep, setActiveStep] = useState(0);
    const [alertShow, setAlertShow] = useState(false);
    const [mensajeAlerta, setMensajeError] = useState("");
    const [skipped, setSkipped] = useState(new Set());
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const isTablet = useMediaQuery("(max-width:1024px)"); // Verifica si la pantalla es móvil
    const isSurface = useMediaQuery("(max-width:1370px)"); // Verifica si la pantalla es móvil
    const isStepOptional = (step) => step === 1;
    const isStepSkipped = (step) => skipped.has(step);
    const alertStep = (message) => alert(message);
    const insertTemporarySession = useInsertTemporarySession(movieParam, iformacionBan);

    const handleNext = (paso) => {
        if (movieParam) {
            insertTemporarySession();
        }
        if (paso === 0) {
            dispatch(updateStep1Complete(true));
            setAlertShow(false);
            setMensajeError("");
        }
        if (paso === 1) {
            const step = localStorage.getItem("step");
            if (!step) {
                if (!isCompleteStep2) {
                    // alertStep("Por favor, seleccione la cantidad de entradas para continuar.");
                    setAlertShow(true);
                    setMensajeError("Por favor, seleccione la cantidad de entradas para continuar.");
                    dispatch(updateStep2Complete(false));
                    return;
                }
                if (step2.quantityPeople === 0) {
                    // alertStep("Por favor, seleccione la cantidad de entradas para continuar.");
                    setAlertShow(true);
                    setMensajeError("Por favor, seleccione la cantidad de entradas para continuar.");
                    dispatch(updateStep2Complete(false));
                    return;
                }
            }

            dispatch(updateStep2Complete(true));
            setAlertShow(false);
            setMensajeError("");
        }

        if (paso === 2) {
            if (step3.seating.length === 0) {
                // alertStep("Por favor, seleccione los asientos necesarios para continuar");
                setAlertShow(true);
                setMensajeError("Por favor, seleccione los asientos necesarios para continuar");
                return;
            }
            const quantityPeopleStep2 = step2.quantityPeople;
            if (step3.seating.length === quantityPeopleStep2) {
                setAlertShow(false);
                setMensajeError("");
                dispatch(updateStep3Complete(true));
            } else {
                // alertStep("La cantidad de asientos seleccionados no coincide con la cantidad de personas seleccionadas en el paso 2. Por favor, verifique.");
                setAlertShow(true);
                setMensajeError("La cantidad de asientos seleccionados no coincide con la cantidad de entradas seleccionadas en el paso 3. Por favor, verifique.");
                return;
            }
        }

        if (paso === 4) {
            const status = true;
            paso = 4;
            localStorage.setItem("step", 5);
            //dispatch(paymentProcess({ paso, status }));
        } else {
            const status = false;
            paso = 0;
            /// dispatch(paymentProcess({ paso, status }));
        }

        if (paso === 4) {
            return;
        }
        let newSkipped = skipped;
        if (isStepSkipped(activeStep)) {
            newSkipped = new Set(newSkipped);
            newSkipped.delete(activeStep);
        }
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);
    };

    const statusRegister = useSelector((state) => state.auth.status);
    const handleBack = () => {
        const step = localStorage.getItem("step");
        if (step) {
            setActiveStep(3);
            localStorage.removeItem("step");
        } else {
            if (activeStep === 0 || (activeStep === 1 && statusRegister === "authenticated")) {
                // Obtener la fecha actual en formato 'YYYY-MM-DD'
                const currentDate = new Date().toISOString().split("T")[0];

                // Obtiene el ID de la película almacenada en localStorage
                const id = localStorage.getItem("idLocalMovie");

                // Despacha la acción para establecer la información de la película activa
                dispatch(setActiveMovieInfo(id));

                // Despacha la acción para actualizar la información de la película
                dispatch(updatearrayInfoMovie());

                // Despacha la acción para obtener tandas, pasando la fecha actual
                dispatch(getTandasBd(currentDate, 1));

                // Navega a la página de la película
                navigate("/movie");
            } else {
                setActiveStep((prevActiveStep) => prevActiveStep - 1);
            }
        }
    };
    const handleSkip = () => {
        if (!isStepOptional(activeStep)) {
            throw new Error("You can't skip a step that isn't optional.");
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped((prevSkipped) => {
            const newSkipped = new Set(prevSkipped);
            newSkipped.add(activeStep);
            return newSkipped;
        });
    };
    const handleReset = () => {
        setActiveStep(0);
        setSkipped(new Set());
    };

    const hasExecuted = useRef(false);

    useEffect(() => {
        if (statusRegister === "authenticated") {
            handleNext(1);
            setActiveStep(1);
        }

        if (statusRegister === "checking") {
            handleNext(0);
            setActiveStep(0);
        }
    }, [statusRegister]);

    const renderStepComponent = (step, payment) => {
        let payments = parseInt(localStorage.getItem("step") || step, 10);
        if (payments === 0 && statusRegister === "authenticated") {
            payments = 1;
        }
        switch (payments) {
            case 0:
                return <Step1 />;
            case 1:
                return <Step2 movieParam={movieParam} />;
            case 2:
                return <Step3 movieParam={movieParam} />;
            case 3:
                return <Step4 movieParam={movieParam} />;
            case 4:
                return <Step5 movieParam={movieParam} />;
            default:
                return <Step5 movieParam={movieParam} />;
        }
    };

    useEffect(() => {
        populateCategorySelect();
    }, []);

    const populateCategorySelect = async () => {
        await dispatch(getAritucule());
    };

    return (
        <Box sx={{ width: "90%", marginTop: isMobile ? "19%" : "4%", backgroundImage: "url('/content/img/SC/fondo-CP.svg')", backgroundSize: "cover", padding: "5%" }}>
            <Backdrop open={loading} style={{ zIndex: 9999 }}>
                <CircularProgress color="success" />
            </Backdrop>
            <Typography style={{ color: "white", fontFamily: "FuturaP-ExtraBold", fontSize: isMobile ? "25px" : "42px", marginTop: isMobile ? "22%" : "0%", position: isMobile ? "absolute" : "relative", textAlign: isMobile || isTablet ? "center" : "left", marginLeft: isMobile ? "13%" : "0%" }}>PROCESO DE COMPRA</Typography>
            <Stack style={{ border: "1px solid rgba(255, 255, 255, 0.17)", borderRadius: "20px", backgroundColor: "rgba(0, 0, 0, 0.24)", padding: isMobile ? "7%" : "2%" }}>
                <Stack style={{ paddingLeft: "10%", paddingRight: "10%" }}>
                    <Stepper activeStep={activeStep}>
                        {steps.map((label, index) => {
                            const stepProps = {};
                            const labelProps = {};

                            return (
                                <Step key={label} {...stepProps}>
                                    <StepLabel {...labelProps} sx={{ flexDirection: isMobile || isTablet ? "column-reverse" : "column", alignItems: "center", textAlign: "center", rowGap: "10px" }}>
                                        {label}
                                    </StepLabel>
                                </Step>
                            );
                        })}
                    </Stepper>
                </Stack>
                {TimeCounter && <CountdownTimer startTime={TimeCounter} responseCode={codeBanck} />}
                {/* Añadir el componente de contador  */}
                {renderStepComponent(activeStep, payment)}
                <React.Fragment>
                    <Box sx={{ display: "flex", flexDirection: "row", pt: 3, justifyContent: "space-between", pl: 13, pr: 13 }}>
                        <>
                            <Button color="inherit" className="btnBack" onClick={handleBack} sx={{ mr: 1 }}>
                                regresar
                            </Button>
                            {alertShow && <Typography style={{ color: "red", textAlign: "center", fontFamily: "FuturaP-Medium", fontSize: isMobile ? "12px" : "15px", width: "50%", display: isMobile ? "none" : "block" }}>{mensajeAlerta}</Typography>}
                            {activeStep !== 4 && !localStorage.getItem("step") && (
                                <Button
                                    className="btnNext"
                                    onClick={() => handleNext(activeStep)}
                                    style={{
                                        marginTop: activeStep === 0 ? (isMobile || isTablet || isSurface ? "0% " : "-13%") : "0%",
                                        display: activeStep === 0 ? "none" : "block",
                                    }}
                                >
                                    {activeStep === 4 ? "PAGAR" : "siguiente"}
                                </Button>
                            )}
                        </>
                    </Box>
                    {alertShow && <Typography style={{ color: "red", textAlign: "center", fontFamily: "FuturaP-Medium", fontSize: isMobile ? "12px" : "15px", width: "50%", display: isMobile ? "block" : "none", marginLeft: "30%" }}>{mensajeAlerta}</Typography>}
                </React.Fragment>
            </Stack>
        </Box>
    );
};

export default Checkout;
