import { Box, useTheme, useMediaQuery, Stack, Typography } from "@mui/material";

function Publicidad() {
    const theme = useTheme(); // Obtiene el tema actual
    const isMobile = useMediaQuery(theme.breakpoints.down("sm")); // Determina si es móvil basado en el tamaño de la pantalla
    const isTablet = useMediaQuery("(max-width:1024px)"); // Verifica si la pantalla es móvil
    const isSurface = useMediaQuery("(max-width:1370px)"); // Verifica si la pantalla es móvil
    return (
        <>
            <Box style={{ width: "100%", height: isMobile ? "30vh" : isTablet ? "200px" : isSurface ? "356px" : "410px", backgroundSize: "cover", backgroundImage: isMobile ? "url(/content/img/SC/bannerSHSM.png)" : "url(/content/img/SC/bannerSHSWDEF.png)", display: "flex", justifyContent: "center", alignItems: "center" }}>
                <Stack direction={isMobile ? "column" : "row"} alignItems="center" justifyContent="center" style={{ width: "100%", height: isMobile ? "20%" : "70%", paddingTop: isMobile ? "0%" : "0%", paddingLeft: isMobile ? "35%" : "0%" }}>
                    <img src="/content/img/SC/Hcinema.png" style={{ width: isMobile ? "159px" : "355px", height: isMobile ? "87px" : "168px" }} />

                    <Stack spacing={isMobile ? 1 : 2}>
                        <Typography style={{ color: "#fff", fontSize: isTablet ? "13px" : "20px", fontFamily: "FuturaP-Light" }}>
                            PARTICIPA PARA GANARTE <br />
                            UN TATUAJE DE HALLOWEEN
                            <br />O UN PLAY STATION 5.
                        </Typography>

                        <a href="https://forms.gle/tDEzh2M7oCTQQXwh6" style={{ width: isTablet ? "120px" : "200px", height: isTablet ? "30px" : "45px", color: "#fff", backgroundColor: "#67BF47", boxShadow: "0px 4px 8px 0px rgba(0, 0, 0, 0.25)", display: "flex", alignContent: "center", justifyContent: "center", alignItems: "center", fontSize: isTablet ? "13px" : "20px", fontFamily: "FuturaP-Bold", borderRadius: "6px", textDecoration: "none" }}>
                            PARTICIPÁ AQUÍ
                        </a>
                    </Stack>
                </Stack>
            </Box>

            {/* banner de publicidad cuando no haya publicidad */}
            {/* <Box style={{ width: "100%", height: isMobile ? "100px" : isTablet ? "225px" : isSurface ? "376px" : "491px", backgroundSize: "cover", backgroundImage: "url(/content/img/SC/bannerPublicidad.jpg)" }}>

            </Box> */}
        </>
    );
}

export default Publicidad;
