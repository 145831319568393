import React, { useState, useEffect } from "react";
import CryptoJS from "crypto-js";
import { Backdrop, CircularProgress, Checkbox, FormControlLabel, Popover, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Cards from "react-credit-cards";
import "react-credit-cards/es/styles-compiled.css";
import { FeeServiceAmount, secretKey } from "../../../api";
import Button from "@mui/material/Button";
import { convertTime } from "./Step1Helpers"; // Asume que esta función existe y está importada correctamente
import MessageDialog from "../componentsGlobal/MessageDialog";
import { restoreStateCheckoutNewBd, setPaymentInProgress, useNmaeUser, useTarjet } from "../../../store/checkout/checkoutSlice";
import useInsertTemporarySession from "../../../hooks/updatePymentClient";
import { restaurarDatosProcesodePago } from "../../../store/auth/authSlice";
import { restoreStateHomeNewBd } from "../../../store/home";
import { startLogout } from "../../../store/auth/thunks";
import { errorMessage, insertartTempInvoice, insertartTempInvoiceAgain } from "../../../store/pymentStoree/pymentStoree";
import { geTemporaryPayment } from "../../../store/checkout";

const Step5 = () => {
    const dispatch = useDispatch();
    const auth = useSelector((state) => state.auth);
    const { status } = useSelector((state) => state.auth);

    // Estado para controlar cuándo se completa el efecto asíncrono
    const [isEffectComplete, setIsEffectComplete] = useState(false);
    const [movieParam, setMovieParam] = useState(null);
    const [iformacionBan, setBanck] = useState("");

    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    const [informacionDelBanco, setinformacionDelBanco] = useState("");
    const [urlPayment, seturlPayment] = useState("url");
    const [responseCodeV, setresponseCodeV] = useState(0);
    const [isResponseCodeVLoaded, setIsResponseCodeVLoaded] = useState(false);
    const [paymentMessage, setPaymentMessage] = useState("");

    // Función asíncrona para ejecutar el efecto
    const executeEffect = async (navigate, dispatch, setMovieParam, setIsEffectComplete, setLoading) => {
        // Obtenemos los parámetros de la URL actual utilizando URLSearchParams
        const urlParams = new URLSearchParams(window.location.search);

        // Extraemos el valor asociado al parámetro 'movie' de la URL
        const movieValue = urlParams.get("movie");
        setMovieParam(movieValue);

        // Verificamos si el valor del parámetro 'movie' es nulo, vacío o indefinido
        if (!movieValue) {
            // Si no se ha seleccionado una película, mostramos una alerta al usuario
            alert("Lo sentimos, no se ha podido verificar la película. Por favor, seleccione una película para continuar.");

            // Redirigimos al usuario a la página de inicio
            navigate("/");

            // Terminamos la ejecución para evitar lógica adicional
            return;
        }

        // Función para obtener los datos de pago temporal desde el servidor
        const fetchData = async () => {
            // Dispatch de la acción para obtener los datos de pago temporal relacionados con la película
            const { data } = await dispatch(geTemporaryPayment(movieValue));
            if (data.statusCode === 200) {
                setLoading(true);

                // Extraemos los datos del primer resultado de la API
                const dataUser = data.data[0];

           

                // console.log("Datos de pago temporal:", dataUser);

                // Restauramos los datos de usuario, estado de la home y del checkout
                // Solo se ejecuta si los datos de pago temporales contienen estos valores
                const { datosUsuario } = dataUser.temporary_payment_data_authUser;
                const { homeState } = dataUser.temporary_payment_data_homeState;
                const { checkoutState } = dataUser.temporary_payment_data_checkoutState;
                const { responseCode } = dataUser.temporary_payment_data_banck;

                // if (datosUsuario) {
                //     await dispatch(restaurarDatosProcesodePago(datosUsuario));
                // }
                // if (homeState) {
                //     await dispatch(restoreStateHomeNewBd(homeState));
                // }
                // if (dataUser.temporary_payment_data_checkoutState) {
                //     await dispatch(restoreStateCheckoutNewBd(dataUser.temporary_payment_data_checkoutState));
                // }

                setBanck(dataUser.temporary_payment_data_banck);
                // Verificamos el valor de 'step' almacenado en localStorage para continuar el proceso
                const step = localStorage.getItem("step");
                if (step) {
                    try {
                        // Si la transacción es aprobada
                        if (responseCode === 100) {
                            seturlPayment(dataUser.temporary_payment_data_checkoutState.tokenSessions);
                            setresponseCodeV(100);
                            setIsResponseCodeVLoaded(true);
                            setLoading(false);
                            return; // Salir del bucle si es exitoso
                        } else {
                            setPaymentMessage(`Error Respuesta del banco: ${dataUser.temporary_payment_data_banck.responseMessage} : ${dataUser.temporary_payment_data_banck.responsetext}`);
                            setIsResponseCodeVLoaded(true);
                            setLoading(false);
                        }
                    } catch (error) {
                        await errorMessage(error);
                    }
                }

                // Si no hay datos del usuario, iniciamos el proceso de logout
                if (datosUsuario.name_user === null) {
                    dispatch(startLogout());
                }

                setLoading(false);
            } else {
                // Error en la obtención de datos de pago temporal
                console.error("Error al obtener los datos de pago temporal:", data);
            }
        };

        // Ejecutamos la función de fetchData y esperamos a que termine
        await fetchData();

        // Indicamos que el efecto ha terminado
        setIsEffectComplete(true);
    };

    // Dentro de useEffect llamamos a la función asíncrona
    useEffect(() => {
        const runEffect = async () => {
            // Llamamos a executeEffect y esperamos su finalización
            await executeEffect(navigate, dispatch, setMovieParam, setIsEffectComplete, setLoading);
        };

        // Ejecutamos el efecto al montar el componente
        runEffect();
    }, [navigate, dispatch, setMovieParam, setIsEffectComplete, setLoading]);

    useEffect(() => {
        // Escuchar los cambios de `status` y mostrar un alert cuando cambie
        if (status === "checking") {
            dispatch(startLogout());
        }
    }, [status]);

    useEffect(() => {
        if (status === "checking") {
            dispatch(startLogout());
        }
    }, []);

    /* LA FUNCION ANTERIOR SE ENCARGA DE RECUPERAR LOS DATOS DE LA PELICULA Y DE LA COMPRA TEMPORAL */

    const { step2, step3, step4, activeTanda, tokenSessions } = useSelector((state) => state.checkout);
    const { allMoviesBillboard } = useSelector((state) => state.home);
    const { arrayInfoMovie } = useSelector((state) => state.home);

    /* importar funciones de ayuda */
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const isTablet = useMediaQuery("(max-width:1024px)"); // Verifica si la pantalla es móvil
    const isSurface = useMediaQuery("(max-width:1370px)"); // Verifica si la pantalla es móvil

    /* informacion de la pelicula y total de pago */
    const imageMaster = allMoviesBillboard.find((movie) => movie.idMasterMovie === activeTanda.idMaster) || "";
    const totalquantityPeople = step2.quantityPeople > 0 ? step2.quantityPeople : 0;
    const FeeService = totalquantityPeople * FeeServiceAmount;
    const totalPriceList = step2.quantityPeople > 0 ? step2.totalStep2 : 0;
    const totalSubTotal = step4.articules.reduce((acc, producto) => acc + producto.subTotal, 0);
    const totalPayment = totalSubTotal + FeeService + totalPriceList;
    const [censura, setCensura] = useState("");

    const [cardData, setCardData] = useState({
        cvc: "",
        expiry: "",
        name: "",
        number: "",
    });

    const [focused, setFocused] = useState("");
    const [errors, setErrors] = useState({
        name: "",
        number: "",
        expiry: "",
        cvc: "",
        terms: "Debe aceptar los términos y condiciones",
        adul: "Acepto la censura indicada",
    });
    const [termsAccepted, setTermsAccepted] = useState(false);
    const [termsAcceptedAdul, setTermsAcceptedAdul] = useState(false);

    const [orderid, setOrderid] = useState("");
    const [amount, setAmount] = useState(totalPayment);

    //const [amount, setAmount] = useState("100");
    const [time, setTime] = useState("");
    const [hash, setHash] = useState("");

    const secretKeyCr = "p5fm35mA6wUcweRz8s8VEx793mRYNWz9";

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handlePopoverOpenInfo = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverCloseInfo = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    const insertTemporarySession = useInsertTemporarySession(movieParam, iformacionBan);

    // Función para generar una ID de orden única
    const generateUniqueOrderId = () => {
        // Generar una cadena aleatoria de solo letras, de 6 caracteres
        const randomPart = Math.random()
            .toString(36)
            .replace(/[^a-z]+/g, "")
            .substr(0, 6);
        const timeNow = Math.floor(Date.now() / 1000);

        // Obtener la hora, minutos y segundos actuales
        const date = new Date();
        const hours = date.getHours().toString().padStart(2, "0");
        const minutes = date.getMinutes().toString().padStart(2, "0");
        const seconds = date.getSeconds().toString().padStart(2, "0");

        // Concatenar todos los elementos en el formato deseado
        return "cineversesh" + randomPart + timeNow + hours + minutes + seconds;
    };

    // Función para crear el hash
    const createHash = (orderid, amount, time, secret) => {
        const hash = CryptoJS.MD5(`${orderid}|${amount}|${time}|${secret}`).toString();
        //console.log("Hash generado:", hash); // Console log para validar el hash
        return hash;
    };

    useEffect(() => {
        const waitForValidTotalPayment = async () => {
            let totalPayment = 0;

            // Espera activa hasta que el totalPayment sea mayor a 0
            while (totalPayment === 0) {
                const totalquantityPeople = step2.quantityPeople > 0 ? step2.quantityPeople : 0;
                const FeeService = totalquantityPeople * FeeServiceAmount;
                const totalPriceList = step2.quantityPeople > 0 ? step2.totalStep2 : 0;
                const totalSubTotal = step4.articules.reduce((acc, producto) => acc + producto.subTotal, 0);
                totalPayment = totalSubTotal + FeeService + totalPriceList;

                if (totalPayment > 0) {
                    setAmount(totalPayment);
                    break;
                }

                // Agrega un pequeño retraso para no bloquear el hilo principal
                await new Promise((resolve) => setTimeout(resolve, 100));
            }

            // Una vez que totalPayment es válido, continúa con el proceso
            const currentTime = Math.floor(Date.now() / 1000); // Generar tiempo en formato epoch
            const newOrderId = generateUniqueOrderId();

            setOrderid(newOrderId);
            setTime(currentTime);
            const generatedHash = createHash(newOrderId, totalPayment, currentTime, secretKeyCr);
            setHash(generatedHash);

            // console.log("Order ID generado:", newOrderId);
            // console.log("Amount:", totalPayment);
            // console.log("Time (epoch):", currentTime);
            // console.log("Hash generado:", generatedHash);
        };

        waitForValidTotalPayment();
    }, [step2, step4]); // Añade las dependencias correctas

    /* acciones que generan la compra temporal y la compra firme */
    // Función asíncrona para generar una factura firme
    const generarFacturaTemporal = async () => {
        setLoading(true);
        await insertTemporarySession();

        // Ejecutamos la acción de insertar la factura firme de confitería y butaca
        const firme = await dispatch(insertartTempInvoice(movieParam));
        // localStorage.setItem("movieParam", movieParam);
        navigate(`/success/${movieParam}/success?response=2&responsetext=INVALID+SECURITY+CODE&authcode=&transactionid=9901038044&avsresponse=U&cvvresponse=P&orderid=Order-2pm521ltr&type=sale&response_code=300&username=9745798&time=1725473307&amount=0.50&hash=f325b1fd7714cdbaeaedb54eeb10891dd7714cdbaeaedb54eeb10891d`);

        if (firme === "error") {
            alert("Lo sentimos, no se ha podido generar la factura firme. Por favor, intente de nuevo.");
            return;
        }

        setLoading(false);
        return;
    };

    /* acciones que generan la compra temporal y la compra firme */

    useEffect(() => {
        if (arrayInfoMovie.length > 0) {
            const censuraValue = arrayInfoMovie[0].censura === "MAYORES 18 AÑOS" ? "Mayores de 18 años" : arrayInfoMovie[0].censura === "TP" ? "TP" : arrayInfoMovie[0].censura === "MAYORES 15 AÑOS" ? "Mayores de 15 años" : arrayInfoMovie[0].censura === "MAYORES 12 AÑOS" ? "Mayores de 12 años" : "Sin clasificación";

            setCensura(censuraValue);
        }
    }, [arrayInfoMovie]);

    const handleInputChange = (e) => {
        const { name, value = "" } = e.target;
        let formattedValue = value || "";

        if (name === "name") {
            formattedValue = value.replace(/[^a-zA-Z\s]/g, "").toUpperCase();
        } else if (name === "number") {
            formattedValue = value
                .replace(/\D/g, "")
                .replace(/(.{4})/g, "$1 ")
                .trim();
            if (formattedValue.length > 19) {
                formattedValue = formattedValue.slice(0, 19);
            }
        } else if (name === "expiry") {
            formattedValue = value.replace(/\D/g, "");
            if (formattedValue.length > 2) {
                formattedValue = formattedValue.slice(0, 2) + "/" + formattedValue.slice(2);
            }
        } else if (name === "cvc") {
            formattedValue = value.replace(/\D/g, "");
        }

        setCardData((prev) => ({ ...prev, [name]: formattedValue }));

        if (name === "number") {
            dispatch(useTarjet(value));
        }

        if (name === "name") {
            dispatch(useNmaeUser(value));
        }

        // Validaciones en tiempo real
        if (name === "name") {
            if (!/^[a-zA-Z\s]*$/.test(value)) {
                setErrors((prev) => ({ ...prev, name: "Solo se permiten letras" }));
            } else if (value.length < 3) {
                setErrors((prev) => ({ ...prev, name: "El nombre debe tener más de 2 caracteres" }));
            } else {
                setErrors((prev) => ({ ...prev, name: "" }));
            }
        } else if (name === "number") {
            const cardNumber = value.replace(/\s/g, "");
            if (!/^\d{15,16}$/.test(cardNumber)) {
                setErrors((prev) => ({ ...prev, number: "El número de tarjeta debe tener 15 o 16 dígitos" }));
            } else {
                setErrors((prev) => ({ ...prev, number: "" }));
            }
        } else if (name === "expiry") {
            if (!/^(0[1-9]|1[0-2])\/\d{2}$/.test(formattedValue)) {
                setErrors((prev) => ({ ...prev, expiry: "El formato debe ser MM/AA" }));
            } else {
                const [month, year] = formattedValue.split("/").map(Number) || [];
                const currentDate = new Date();
                const currentMonth = currentDate.getMonth() + 1;
                const currentYear = Number(currentDate.getFullYear().toString().slice(-2));
                if (year < currentYear || (year === currentYear && month < currentMonth)) {
                    setErrors((prev) => ({ ...prev, expiry: "La tarjeta está vencida" }));
                } else {
                    setErrors((prev) => ({ ...prev, expiry: "" }));
                }
            }
        } else if (name === "cvc") {
            if (!/^\d{3,4}$/.test(value)) {
                setErrors((prev) => ({ ...prev, cvc: "El CVC debe tener 3 o 4 dígitos" }));
            } else {
                setErrors((prev) => ({ ...prev, cvc: "" }));
            }
        }
    };

    const handleInputFocus = (e) => {
        setFocused(e.target.name);
    };

    const handleTermsChange = (e) => {
        setTermsAccepted(e.target.checked);
        if (e.target.checked) {
            setErrors((prev) => ({ ...prev, terms: "" }));
        } else {
            setErrors((prev) => ({ ...prev, terms: "Debe aceptar los términos y condiciones" }));
        }
    };

    const handleTermsChangeAdul = (e) => {
        setTermsAcceptedAdul(e.target.checked);
        if (e.target.checked) {
            setErrors((prev) => ({ ...prev, adul: "" }));
        } else {
            setErrors((prev) => ({ ...prev, adul: "Acepto la censura Mayores de 18 años." }));
        }
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        localStorage.setItem("movieParam", movieParam);
        // Extraer el dominio actual (sin importar si es www o no)
        const currentDomain = window.location.origin; // Esto dará "https://studiocinemascr.com" o "https://www.studiocinemascr.com"

        // 1. Generar un nuevo orderid para cada intento de transacción
        const generateUniqueOrderIds = () => {
            // Generar una cadena aleatoria de solo letras, de 6 caracteres
            const randomPart = Math.random()
                .toString(36)
                .replace(/[^a-z]+/g, "")
                .substr(0, 6);
            const timeNow = Math.floor(Date.now() / 1000);

            // Obtener la hora, minutos y segundos actuales
            const date = new Date();
            const hours = date.getHours().toString().padStart(2, "0");
            const minutes = date.getMinutes().toString().padStart(2, "0");
            const seconds = date.getSeconds().toString().padStart(2, "0");

            // Concatenar todos los elementos en el formato deseado
            return "cineverse-" + randomPart + timeNow + hours + minutes + seconds;
        };

        let orderid = generateUniqueOrderIds(); // Generar nuevo orderid para esta transacción
        // 2. Validar el número de tarjeta (debe ser de 16 dígitos)
        let ccnumber = cardData.number.replace(/\s/g, "");
        if (ccnumber.length < 15 || ccnumber.length > 16 || !/^\d+$/.test(ccnumber)) {
            alert("Error: El número de tarjeta debe tener 15 o 16 dígitos.");
            setLoading(false);
            return;
        }

        // 3. Validar la fecha de expiración (formato MMYY)
        let ccexp = cardData.expiry.replace("/", "");
        if (ccexp.length !== 4 || !/^\d+$/.test(ccexp)) {
            alert("Error: La fecha de expiración debe estar en el formato MMYY.");
            setLoading(false);
            return;
        }

        // 4. Validar el CVV (debe ser de 3 dígitos)
        let cvv = cardData.cvc;
        if (cvv.length < 3 || cvv.length > 4 || !/^\d+$/.test(cvv)) {
            alert("Error: El CVV debe tener 3 o 4 dígitos.");
            setLoading(false);
            return;
        }

        // 5. Validar el monto (debe ser un número válido con dos decimales)
        let amount = parseFloat(totalPayment).toFixed(2);
        if (isNaN(amount) || amount <= 0) {
            alert("Error: El monto debe ser un número válido mayor que 0.");
            setLoading(false);
            return;
        }

        // 6. Generar el hash después de las validaciones
        let hash = createHash(orderid, amount, time, secretKeyCr);
        if (!hash) {
            alert("Error: No se pudo generar el hash. Favor de intentar de nuevo.");
            setLoading(false);
            return;
        }

        // 7. Continuar con el flujo normal después de validar y generar el hash
        await insertTemporarySession();
        const firme = await dispatch(insertartTempInvoice(movieParam));

        // 8. Crear el formulario y enviar los datos validados
        const form = document.createElement("form");
        form.setAttribute("method", "post");
        form.setAttribute("action", "https://credomatic.compassmerchantsolutions.com/api/transact.php");

        // 9. Añadir los campos ocultos al formulario dinámico
        const hiddenFields = [
            { name: "type", value: "sale" },
            { name: "key_id", value: "9745798" },
            { name: "hash", value: hash },
            { name: "time", value: time },
            { name: "redirect", value: `${currentDomain}/success/${movieParam}/success` },
            { name: "orderid", value: orderid }, // Usar el nuevo orderid generado
            { name: "amount", value: amount },
            { name: "ccnumber", value: ccnumber },
            { name: "ccexp", value: ccexp },
            { name: "cvv", value: cvv },
        ];

        // 10. Hacer un console.log para verificar qué datos se están enviando
        //console.log("Datos enviados al formulario de Credomatic:", hiddenFields);

        hiddenFields.forEach((field) => {
            const input = document.createElement("input");
            input.setAttribute("type", "hidden");
            input.setAttribute("name", field.name);
            input.setAttribute("value", field.value);
            form.appendChild(input);
        });

        document.body.appendChild(form);
        form.submit();
    };

    const isFormValid = () => {
        return cardData.name && cardData.number.replace(/\s/g, "").length >= 15 && /^(0[1-9]|1[0-2])\/\d{2}$/.test(cardData.expiry) && !errors.expiry && cardData.cvc.length >= 3 && termsAccepted && termsAcceptedAdul;
    };

    return (
        <Stack direction={isMobile || isTablet ? "column" : "row"} justifyContent={isMobile || isTablet ? "space-evenly" : "flex-start"} alignItems={isMobile || isTablet ? "center" : "flex-start"} spacing={isMobile || isTablet ? 12 : 20} style={{ marginTop: isMobile ? "0%" : "2%", paddingLeft: "0%" }}>
            <Backdrop open={loading} style={{ zIndex: 9999 }}>
                <CircularProgress color="success" />
            </Backdrop>
            <Stack direction={isMobile || isTablet ? "row" : "column"} spacing={isMobile || isTablet ? 2 : 1} alignItems={isMobile || isTablet ? "center" : "flex-start"}>
                <img src={imageMaster.image} alt="imagen" width={isMobile ? "120px" : "175px"} height={isMobile ? "155px" : "247px"} />
                <Stack>
                    <Stack direction="row" spacing={1}>
                        <Typography style={{ color: "white", fontFamily: "FuturaP-Bold", textTransform: "uppercase", fontSize: isMobile ? "12px" : "13px" }}>Pelicula:</Typography>
                        <Typography style={{ color: "white", fontFamily: "FuturaP-Light", fontWeight: "100", textTransform: "uppercase", fontSize: isMobile ? "12px" : "13px" }}>{activeTanda.name_movie_bp || ""}</Typography>
                    </Stack>

                    <Stack direction="row" spacing={1}>
                        <Typography style={{ color: "white", fontFamily: "FuturaP-Bold", textTransform: "uppercase", fontSize: isMobile ? "12px" : "13px" }}>DÍA:</Typography>
                        <Typography style={{ color: "white", fontFamily: "FuturaP-Light", fontWeight: "100", textTransform: "uppercase", fontSize: isMobile ? "12px" : "13px" }}>{activeTanda && activeTanda.date_time_shifts_bp ? convertTime(activeTanda.date_time_shifts_bp) : "Fecha no disponible"}</Typography>
                    </Stack>

                    <Stack direction="row" spacing={1}>
                        <Typography style={{ color: "white", fontFamily: "FuturaP-Bold", textTransform: "uppercase", fontSize: isMobile ? "12px" : "13px" }}>SALA:</Typography>
                        <Typography style={{ color: "white", fontFamily: "FuturaP-Light", fontWeight: "100", textTransform: "uppercase", fontSize: isMobile ? "12px" : "13px" }}>{activeTanda.room_name_shifts_bp || ""}</Typography>
                    </Stack>

                    <Stack direction="row" spacing={1}>
                        <Typography style={{ color: "white", fontFamily: "FuturaP-Bold", textTransform: "uppercase", fontSize: isMobile ? "12px" : "13px" }}>HORA:</Typography>
                        <Typography style={{ color: "white", fontFamily: "FuturaP-Light", fontWeight: "100", textTransform: "uppercase", fontSize: isMobile ? "12px" : "13px" }}>{activeTanda.hora || ""}</Typography>
                    </Stack>
                </Stack>
            </Stack>

            <Stack spacing={isMobile || isSurface || isTablet ? 2 : 5} direction="column" justifyContent="flex-start" alignItems={isMobile || isTablet ? "center" : "flex-start"} style={{ width: isSurface ? "70%" : "100%", paddingLeft: isMobile ? "10%" : "0%", marginTop: isMobile || isTablet ? "20%" : "0%" }}>
                <Stack direction="row" spacing={2} style={{ marginTop: isMobile || isTablet ? "-25%" : "0%", marginLeft: isMobile || isTablet ? "-25%" : "0%" }}>
                    <Typography style={{ color: "white", fontFamily: "FuturaP-ExtraBold", textTransform: "uppercase", fontSize: isMobile ? "15px" : "32px", backgroundColor: "#66B02E", paddingTop: isMobile ? "3px" : "3px", paddingLeft: isMobile ? "8px" : "15px", paddingRight: isMobile ? "8px" : "14px", paddingBottom: isMobile ? "1px" : "1px", borderRadius: "100%", display: isMobile ? "block" : "none" }}>5</Typography>
                    <Typography style={{ color: "white", fontFamily: "FuturaP-ExtraBold", textTransform: "uppercase", fontSize: isMobile ? "15px" : "32px", fontWeight: "100" }}>CONFIRMACIÓN Y PAGO</Typography>
                </Stack>

                <Stack direction="column" justifyContent="center" alignItems={isMobile || isTablet ? "center" : "space-evenly"} spacing={2} style={{ marginTop: isMobile || isSurface || isTablet ? "0%" : "0%", marginLeft: isMobile ? "0%" : "0%", border: isMobile ? "none" : "1px solid rgba(255, 255, 255, 0.20)", padding: isMobile ? "1%" : "2%", backgroundColor: isMobile ? "transparent" : "rgba(0, 0, 0, 0.24)", borderRadius: "20px", width: "95%" }}>
                    <Typography style={{ color: "white", fontFamily: "FuturaP-Light", fontWeight: "100", textTransform: "uppercase", fontSize: isMobile ? "15px" : "24px" }}>DETALLES DE ORDEN</Typography>
                    <Stack direction="row" justifyContent={"space-around"}>
                        <Stack>
                            <Typography style={{ color: "white", fontFamily: "FuturaP-ExtraBold", textTransform: "uppercase", fontSize: isMobile ? "15px" : "24px", display: isMobile ? "none" : "block" }}>Santa Ana - City Place</Typography>
                            <Typography style={{ color: "white", fontFamily: "FuturaP-ExtraBold", textTransform: "uppercase", fontSize: isMobile ? "15px" : "20px", display: isMobile ? "none" : "block" }}>{activeTanda.name_movie_bp || ""}</Typography>
                            <Typography
                                style={{
                                    color: "white",
                                    fontFamily: "FuturaP-Light",
                                    fontWeight: "100",
                                    textTransform: "uppercase",
                                    fontSize: isMobile ? "15px" : "15px",
                                    display: isMobile ? "none" : "block",
                                }}
                            >
                                {activeTanda && activeTanda.date_time_shifts_bp ? convertTime(activeTanda.date_time_shifts_bp) : "Fecha no disponible"} {activeTanda?.hora || ""}
                            </Typography>
                        </Stack>
                        <Stack>
                            <Typography style={{ color: "white", fontFamily: "FuturaP-Bold", fontSize: isMobile ? "15px" : "20px", display: isMobile || isTablet ? "block" : "block" }}>
                                {auth.name_user} {auth.lasname_user}
                            </Typography>

                            <Typography
                                style={{
                                    color: "white",
                                    fontFamily: "FuturaP-Light",
                                    fontSize: "16px",
                                    fontWeight: "100",
                                    display: isMobile || isTablet ? "block" : "block",
                                    textTransform: "none",
                                }}
                            >
                                {auth.email_user}
                            </Typography>
                        </Stack>
                    </Stack>
                    <Stack direction="row" justifyContent="space-evenly" alignItems="center">
                        <Stack spacing={1}>
                            <Typography style={{ color: "white", fontFamily: "FuturaP-Bold", textTransform: "uppercase", fontSize: isMobile ? "12px" : "16px", paddingTop: "5%" }}>entradas</Typography>
                            {step2.priceList
                                .filter((item) => item.quantity > 0)
                                .map((item, index) => (
                                    <Stack key={item.id + index} direction="row" justifyContent="space-between" alignItems="center" style={{ paddingLeft: isMobile ? "0px" : "20px" }}>
                                        <Typography style={{ color: "white", fontFamily: "FuturaP-Light", textTransform: "uppercase", fontWeight: "100", fontSize: isMobile ? "9px" : "15px" }}>
                                            {item.name} ({item.quantity})
                                        </Typography>
                                        <Typography style={{ color: "white", fontFamily: "FuturaP-Light", textTransform: "uppercase", fontWeight: "100", fontSize: isMobile ? "9px" : "15px" }}> ₡ {new Intl.NumberFormat("en-US", { minimumFractionDigits: 0, maximumFractionDigits: 0 }).format([item.subtotal])}</Typography>
                                    </Stack>
                                ))}

                            <Stack direction="row" justifyContent="flex-start" alignItems="center" style={{ paddingLeft: isMobile ? "0px" : "20px" }} spacing={1}>
                                <Typography style={{ fontFamily: "FuturaP-Bold", color: "white", textTransform: "uppercase", fontSize: "12px" }}>asientos :</Typography>
                                <Typography style={{ fontFamily: "FuturaP-Bold", color: "white", textTransform: "uppercase", fontSize: "12px" }}>
                                    {step3.seating.length === 1
                                        ? step3.seating[0].nombreButaca
                                        : step3.seating.map((item, index) => (
                                              <span key={item.nombreButaca}>
                                                  {item.nombreButaca}
                                                  {index < step3.seating.length - 1 && ", "}
                                              </span>
                                          ))}
                                </Typography>
                            </Stack>

                            <Stack direction="row" justifyContent="space-between" alignItems="center">
                                <Typography style={{ color: "white", fontFamily: "FuturaP-Bold", textTransform: "uppercase", fontWeight: "100", fontSize: isMobile ? "9px" : "16px" }}>CARGO DE SERVICIO</Typography>
                                <Typography style={{ color: "white", fontFamily: "FuturaP-Light", textTransform: "uppercase", fontWeight: "100", fontSize: isMobile ? "9px" : "15px" }}>₡ {new Intl.NumberFormat("en-US", { minimumFractionDigits: 0, maximumFractionDigits: 0 }).format([FeeService])}</Typography>
                            </Stack>
                            <Typography style={{ color: "white", fontFamily: "FuturaP-Light", textTransform: "uppercase", fontWeight: "100", fontSize: isMobile ? "8px" : "13px" }}>Cargo por boleto ₡{FeeServiceAmount} colones</Typography>
                        </Stack>

                        <Stack spacing={1}>
                            <Typography style={{ color: "white", fontFamily: "FuturaP-Bold", textTransform: "uppercase", fontSize: isMobile ? "12px" : "16px" }}>CONFITERÍA</Typography>

                            {step4.articules.length === 0 ? (
                                <Typography style={{ color: "white", fontFamily: "FuturaP-Light", textTransform: "uppercase", fontWeight: "100", fontSize: isMobile ? "8px" : "13px" }}>Sin productos seleccionados</Typography>
                            ) : (
                                step4.articules.map((producto, index) => (
                                    <Stack key={producto.idArt + producto.key + index} direction="row" justifyContent="space-between">
                                        <Stack>
                                            <Stack direction="row" spacing={1}>
                                                <Typography className="detalleOrden">{producto.cantProduct}</Typography>
                                                <Stack>
                                                    <Typography className="detalleOrden">{producto.nombre}</Typography>
                                                    <Stack direction="row" spacing={0.5}>
                                                        <Typography className="detalle2Orden">{producto.size.name && `${producto.size.name} ,`}</Typography>
                                                        <Typography className="detalle2Orden">{producto.flavors.name && `${producto.flavors.name} ,`} </Typography>
                                                        {producto.drinks.map((drink, drinkIndex) => (
                                                            <Typography key={`${drink.name}-${drink.id}-${drink.key}-${producto.idArt}-${producto.key}-${index}-${drinkIndex}`} className="detalle2Orden">
                                                                {drink.name && `${drink.name},`}
                                                            </Typography>
                                                        ))}
                                                        {producto.extras.map((extra, extraIndex) => (
                                                            <Typography key={`${extra.id}-${extra.key}-${producto.idArt}-${producto.key}-${index}-${extraIndex}`} className="detalle2Orden">
                                                                {extra.name && `${extra.name},`}
                                                            </Typography>
                                                        ))}
                                                    </Stack>
                                                </Stack>
                                            </Stack>
                                        </Stack>
                                        <Typography className="detalleOrden"> ₡ {new Intl.NumberFormat("en-US", { minimumFractionDigits: 0, maximumFractionDigits: 0 }).format([producto.subTotal])}</Typography>
                                    </Stack>
                                ))
                            )}
                            <br />
                            <Stack direction="row" justifyContent="space-between" alignItems="center">
                                <Typography style={{ color: "white", fontFamily: "FuturaP-Bold", textTransform: "uppercase", fontSize: isMobile ? "9px" : "24px" }}>total</Typography>
                                <Typography style={{ color: "white", fontFamily: "FuturaP-ExtraBold", textTransform: "uppercase", fontSize: isMobile ? "10px" : "40px" }}> ₡ {new Intl.NumberFormat("en-US", { minimumFractionDigits: 0, maximumFractionDigits: 0 }).format([totalPayment])}</Typography>
                            </Stack>
                        </Stack>
                    </Stack>
                </Stack>
                <Stack direction={isMobile ? "column" : "row"} spacing={isMobile ? 2 : 8} justifyContent="center" alignItems="center" style={{ border: isMobile ? "none" : "1px solid rgba(255, 255, 255, 0.20)", backgroundColor: isMobile ? "transparent" : "rgba(0, 0, 0, 0.24)", borderRadius: "20px", padding: "4%", marginLeft: isMobile ? "0%" : "0%" }}>
                    <Stack justifyContent="center" alignItems={isMobile ? "center" : "flex-start"}>
                        <Typography style={{ color: "white", fontFamily: "FuturaP-Bold", textTransform: "uppercase", fontSize: isMobile ? "15px" : "16px" }}>CÓDIGOS PROMOCIONALES</Typography>
                        <Typography style={{ color: "white", fontFamily: "FuturaP-Light", textTransform: "uppercase", fontWeight: "100", fontSize: isMobile ? "9px" : "14px", textAlign: "center" }}>INGRESÁ ACÁ TUS CÓDIGOS PROFESIONALES Y OBTEN DESCUENTOS EN TU ORDEN</Typography>
                    </Stack>

                    <input className="inputPromo" type="text" />
                </Stack>

                {isResponseCodeVLoaded ? responseCodeV === 100 ? <MessageDialog title="Compra realizada con éxito" icon="/content/img/SC/check.svg" url={urlPayment} description="Descarga el PDF de tu compra haciendo clic en el botón." download={true} /> : <MessageDialog title="Transacción denegada." description="Asegúrate de que los datos en los campos sean correctos." icon="/content/img/SC/alertIcon.png" width="50%" alert={true} /> : null}
                <Typography style={{ color: "rgba(199, 199, 199, 1)", fontFamily: "FuturaP-ExtraBold", textTransform: "uppercase", fontSize: isMobile ? "15px" : "24px" }}>MÉTODO DE PAGO</Typography>
                {paymentMessage && <Typography style={{ color: "white", fontFamily: "FuturaP-Medium", fontSize: "15px", textTransform: "uppercase", marginTop: "10px" }}>{paymentMessage}</Typography>}
                <Stack direction={isMobile || isSurface ? "column" : "row"} style={{ border: isMobile ? "none" : "1px solid rgba(255, 255, 255, 0.20)", backgroundColor: isMobile ? "transparent" : "rgba(0, 0, 0, 0.24)", borderRadius: "20px", padding: "3%", marginTop: "0%" }} spacing={2}>
                    <Cards cvc={cardData.cvc} expiry={cardData.expiry} name={cardData.name} number={cardData.number} focused={focused} />
                    <Stack direction="column" spacing={2}>
                        <Stack>
                            <Typography style={{ color: "rgba(199, 199, 199, 1)", fontFamily: "FuturaP-Bold", textTransform: "uppercase", fontSize: isMobile ? "15px" : "16px" }}>NOMBRE EN LA TARJETA *</Typography>
                            <input className="inputMP" type="text" name="name" value={cardData.name} onChange={handleInputChange} onFocus={handleInputFocus} style={{ textTransform: "uppercase" }} />
                            {errors.name && <Typography style={{ color: "red", fontFamily: "FuturaP-Medium", fontSize: "11px", textTransform: "uppercase" }}>{errors.name}</Typography>}
                        </Stack>
                        <Stack style={{ display: isMobile || isSurface ? "block" : "none" }}>
                            <Typography style={{ color: "rgba(199, 199, 199, 1)", fontFamily: "FuturaP-Bold", textTransform: "uppercase", fontSize: isMobile ? "15px" : "16px" }}>NÚMERO DE TARJETA *</Typography>
                            <input className="inputMP" type="text" name="number" value={cardData.number} onChange={handleInputChange} onFocus={handleInputFocus} />
                            {errors.number && <Typography style={{ color: "red", fontFamily: "FuturaP-Medium", fontSize: "11px", textTransform: "uppercase" }}>{errors.number}</Typography>}
                        </Stack>
                        <Stack style={{ display: isMobile || isSurface ? "none" : "block" }}>
                            <Typography style={{ color: "rgba(199, 199, 199, 1)", fontFamily: "FuturaP-Bold", textTransform: "uppercase", fontSize: isMobile ? "15px" : "16px" }}>EXPIRA *</Typography>
                            <input className="inputMP" type="text" name="expiry" value={cardData.expiry} onChange={handleInputChange} onFocus={handleInputFocus} placeholder="MM/YY" />
                            {errors.expiry && <Typography style={{ color: "red", fontFamily: "FuturaP-Medium", fontSize: "11px", textTransform: "uppercase" }}>{errors.expiry}</Typography>}
                        </Stack>
                        <Stack direction="row">
                            <Stack style={{ display: isMobile || isSurface ? "block" : "none" }}>
                                <Typography style={{ color: "rgba(199, 199, 199, 1)", fontFamily: "FuturaP-Bold", textTransform: "uppercase", fontSize: isMobile ? "15px" : "16px" }}>EXPIRA *</Typography>
                                <input className="inputMP" type="text" name="expiry" value={cardData.expiry} onChange={handleInputChange} onFocus={handleInputFocus} placeholder="MM/YY" style={{ width: "100px" }} />
                                {errors.expiry && <Typography style={{ color: "red", fontFamily: "FuturaP-Medium", fontSize: "11px", textTransform: "uppercase" }}>{errors.expiry}</Typography>}
                            </Stack>

                            <Stack direction={isMobile || isSurface ? "column" : "row"} justifyContent="flex-start" alignItems="center" spacing={1}>
                                <Stack spacing={0}>
                                    <Typography style={{ color: "rgba(199, 199, 199, 1)", fontFamily: "FuturaP-Bold", textTransform: "uppercase", fontSize: isMobile ? "15px" : "16px" }}>CVC *</Typography>
                                    <input className="inputMP" type="password" name="cvc" value={cardData.cvc} onChange={handleInputChange} onFocus={handleInputFocus} maxLength="4" style={{ width: isMobile ? "100px" : "116px" }} />
                                    {errors.cvc && <Typography style={{ color: "red", fontFamily: "FuturaP-Medium", fontSize: "11px", textTransform: "uppercase" }}>{errors.cvc}</Typography>}
                                </Stack>
                                <Typography style={{ color: "rgba(255, 255, 255, 1)", fontFamily: "FuturaP-Light", fontWeight: "100", fontSize: isMobile ? "8px" : "12px" }}>
                                    El código de 3 o 4 dígitos en la parte
                                    <br />
                                    posterior de su tarjeta
                                </Typography>
                            </Stack>
                        </Stack>
                    </Stack>

                    <Stack direction="column" spacing={0}>
                        <Stack style={{ display: isMobile || isSurface ? "none" : "block" }}>
                            <Typography style={{ color: "rgba(199, 199, 199, 1)", fontFamily: "FuturaP-Bold", textTransform: "uppercase", fontSize: isMobile ? "15px" : "16px" }}>NÚMERO DE TARJETA *</Typography>
                            <input className="inputMP" type="text" name="number" value={cardData.number} onChange={handleInputChange} onFocus={handleInputFocus} />
                            {errors.number && <Typography style={{ color: "red", fontFamily: "FuturaP-Medium", fontSize: "11px", textTransform: "uppercase" }}>{errors.number}</Typography>}
                        </Stack>
                        <FormControlLabel value="end" control={<Checkbox defaultChecked />} label="Deseo recibir información y ofertas de Studio Cinemas." labelPlacement="end" style={{ marginTop: "8%" }} />
                        <Stack direction="row" alignItems="center">
                            <FormControlLabel value="end" control={<Checkbox checked={termsAcceptedAdul} onChange={handleTermsChangeAdul} />} label={`Acepto la censura ${censura}.`} labelPlacement="end" />

                            <img src="/content/img/SC/info.png" aria-owns={open ? "mouse-over-popover" : undefined} aria-haspopup="true" onMouseEnter={handlePopoverOpenInfo} onMouseLeave={handlePopoverCloseInfo} style={{ width: "20px", height: "20px" }} />

                            <Popover
                                id="mouse-over-popover"
                                sx={{
                                    pointerEvents: "none",
                                }}
                                open={open}
                                anchorEl={anchorEl}
                                anchorOrigin={{
                                    vertical: "bottom",
                                    horizontal: "left",
                                }}
                                transformOrigin={{
                                    vertical: "top",
                                    horizontal: "left",
                                }}
                                onClose={handlePopoverCloseInfo}
                                disableRestoreFocus
                                disableScrollLock
                                slotProps={{ paper: { sx: { width: isMobile || isTablet ? "50%" : "10%" } } }}
                            >
                                <Typography sx={{ p: 1, backgroundColor: "#66B02E", color: "#fff", fontFamily: "FuturaP-Medium" }}>Por la ley Nº 7440 se exigira la tarjeta de identificacion de mayores para las peliculas con clasificación {censura}.</Typography>
                            </Popover>
                        </Stack>

                        <FormControlLabel
                            value="end"
                            control={<Checkbox checked={termsAccepted} onChange={handleTermsChange} />}
                            label={
                                <span>
                                    Acepto los{" "}
                                    <Link to="#" style={{ color: "#2C98E0" }}>
                                        términos y condiciones
                                    </Link>
                                    de uso.
                                </span>
                            }
                            labelPlacement="end"
                        />
                        {errors.adul && <Typography style={{ color: "red", fontFamily: "FuturaP-Medium", fontSize: "11px", textTransform: "uppercase" }}>{errors.adul}</Typography>}
                        {errors.terms && <Typography style={{ color: "red", fontFamily: "FuturaP-Medium", fontSize: "11px", textTransform: "uppercase" }}>{errors.terms}</Typography>}
                        <form id="credomaticForm" onSubmit={handleSubmit}>
                            <Button type="submit" className="btnNext" disabled={!isFormValid()} style={{ backgroundColor: isFormValid() ? "#66B02E" : "#ccc", marginTop: "20%" }}>
                                PAGAR
                            </Button>
                            {/* <br></br>
                            <br></br>
                            <Button className="btnNext" style={{ marginTop: "10%" }} onClick={generarFacturaTemporal}>
                                Generar Temporal
                            </Button>  */}
                        </form>
                    </Stack>
                </Stack>
            </Stack>
        </Stack>
    );
};

export default Step5;
