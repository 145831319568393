import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Backdrop, CircularProgress } from "@mui/material";
import { useDispatch } from "react-redux";
import { actualizarDatosCompra, errorMessage, insertarErrorDeFactura, insertartFirmeInvoice } from "../../../store/pymentStoree/pymentStoree"; // Acción para actualizar los datos de la compra en el store de Redux

// Componente principal de confirmación de pago
export const Confirmations = () => {

    
    const location = useLocation(); // Hook para obtener la URL actual
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [loading, setLoading] = useState(true); // Estado para manejar el preloader durante la carga de los datos
    const [error, setError] = useState(null); // Estado para manejar errores en la operación
    const [movieParam, setMovieParam] = useState(null); // Estado para manejar el valor de movieParam

    // Mapeo de los códigos de respuesta a mensajes legibles
    const responseMessages = {
        100: "Transacción aprobada",
        200: "Transacción denegada por el banco emisor",
        201: "Transacción no autorizada",
        202: "Fondos insuficientes",
        203: "Límite excedido",
        204: "Transacción no permitida",
        220: "Datos de pago incorrectos",
        221: "Tarjeta inválida",
        222: "Tarjeta inexistente",
        223: "Tarjeta expirada",
        224: "Fecha de expiración inválida",
        225: "Código de seguridad inválido",
        240: "Llamar al emisor de la tarjeta para más información",
        250: "Tarjeta requerida por el banco emisor",
        251: "Tarjeta extraviada",
        252: "Tarjeta robada",
        253: "Tarjeta fraudulenta",
        260: "Transacción denegada, ver detalle",
        300: "Transacción denegada por el servicio",
        400: "Error en la transacción recibido por el servicio",
        460: "Característica del servicio no disponible",
        461: "Tarjeta no soportada",
         0: "ERROR DE CONEXIÓN",
    };

    // Función asíncrona para extraer y procesar los detalles de la URL
    const processUrlParams = async () => {
        // Guardamos el paso actual en el localStorage desde el inicio
        localStorage.setItem("step", 5);

        const queryParams = new URLSearchParams(location.search); // Extraemos los parámetros de la URL
        const path = location.pathname;

        // Usar una expresión regular para capturar el patrón de la URL que mencionas
        const regex = /\/success\/([^\/]+)\/success/;
        const match = path.match(regex);

        const storedParam = match[1]; // Extraemos el valor de la URL
        const currentUrl = window.location.href;

        const queryParamsObj = Object.fromEntries(queryParams.entries());

        // Enviar los parámetros de la URL a Redux lo antes posible
        await dispatch(actualizarDatosCompra(storedParam, queryParamsObj)); // Convertimos queryParams a objeto y lo enviamos

        // Función para obtener los valores de los parámetros de la URL
        const getParam = (param, defaultValue = "--") => {
            const value = queryParams.get(param); // Obtenemos el valor del parámetro
            // Si el valor es null o una cadena vacía, devolvemos el valor por defecto
            return value !== null && value !== "" ? value : defaultValue;
        };

        // Extraemos y procesamos los valores de los parámetros de la URL
        const response = getParam("response");
        const transactionid = getParam("transactionid");
        const amount = getParam("amount", "0.00"); // El monto, por defecto es "0.00"
        const orderid = getParam("orderid");
        const time = getParam("time");
        const receivedHash = getParam("hash");
        const responseCode = getParam("response_code", "0"); // Código de respuesta, por defecto "0"
        const responsetext = getParam("responsetext");
        const avsresponse = getParam("avsresponse");
        const cvvresponse = getParam("cvvresponse");
        const authcode = getParam("authcode");

        // Convertimos el código de respuesta a entero y obtenemos el mensaje correspondiente
        const responseCodeInt = parseInt(responseCode, 10);
        const responseMessage = responseMessages[responseCodeInt] || responseMessages[0]; // Mensaje por defecto si no existe el código

        // Creamos un objeto con todos los detalles de la transacción
        const transactionDetails = {
            response,
            transactionid,
            amount,
            orderid,
            time,
            receivedHash,
            responseCode: responseCodeInt,
            responseMessage,
            responsetext,
            avsresponse,
            cvvresponse,
            authcode,
            currentUrl,
            tempmovieParam: storedParam,
        };

        // Convertimos los detalles a JSON para almacenarlos o procesarlos más fácilmente
        const jsonDetails = JSON.stringify(transactionDetails, null, 2);

        // Ya no es necesaria la verificación de 'step', porque ya lo hemos guardado
        // Actualizamos los datos de la compra usando Redux y despachando la acción correspondiente
        await dispatch(actualizarDatosCompra(storedParam, jsonDetails));

        if (responseCodeInt === 100) {
            await dispatch(insertartFirmeInvoice(storedParam));
        } else {
            await dispatch(insertarErrorDeFactura(storedParam, transactionDetails));
        }

        // Navegamos a la siguiente página del flujo de compra con el parámetro `movie`
        navigate(`/checkout?movie=${storedParam}`);

        // Simulamos un pequeño retardo para ver el preloader
        await new Promise((resolve) => setTimeout(resolve, 1));

        setLoading(false); // Indicamos que la carga de datos ha finalizado
    };


    // useEffect para ejecutar la función de obtención de detalles al montar el componente
    useEffect(() => {
        const handleProcess = async () => {
            try {
                await processUrlParams();
            } catch(errors) {
                // En caso de error, mostramos un mensaje y lo almacenamos en el estado
                await errorMessage(errors);
            }
        };

        handleProcess(); // Llamamos a la función de procesamiento de la URL
    }, [location.search]); // Dependemos de los parámetros de la URL para ejecutar este efecto

    // Interfaz de usuario para mostrar los detalles o un preloader mientras se cargan
    return (
        <div>
            {loading ? (
                // Mostrar preloader mientras los datos se están cargando
                <div
                    style={{
                        position: "fixed",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        backgroundColor: "rgb(0 0 0)",
                        color: "white",
                        zIndex: 9999,
                    }}
                >
                    <Backdrop open={loading} style={{ zIndex: 9999 }}>
                        <CircularProgress color="success" />
                    </Backdrop>
                    <div
                        style={{
                            marginTop: "20px",
                            fontSize: "18px",
                            fontWeight: "bold",
                            textAlign: "center",
                        }}
                    >
                        Procesando pago...
                    </div>
                </div>
            ) : error ? (
                // En caso de error, mostrar un mensaje en rojo
                <div style={{ color: "red" }}>{error}</div>
            ) : (
                // Aquí irían los detalles de la transacción si están disponibles
                <></>
            )}
        </div>
    );
};
